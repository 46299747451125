import { Box, useStyleConfig } from "@chakra-ui/react";
function PanelContainer(props) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("PanelContainer", { variant });
  // Pass the computed styles into the `__css` prop
  return (
    <Box __css={styles} pb={{ base: 5 }} p={{ base: 0, md: 10 }} {...rest}>
      {children}
    </Box>
  );
}

export default PanelContainer;
